import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


export default class DubaiCV extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <ArticleFrame ArticleDataName="DubaiCV" dubai={this.props.dubai} >
        <p></p>
        <p>If you&rsquo;re an architectural professional looking to relocate to Dubai and you&rsquo;re unsure what to include in your CV or what hiring managers and recruiters are looking for, then this article is for you. With Dubai attracting increasing interest from overseas-qualified architects, especially from the UK, the competition is fierce. Of course, if you&rsquo;ve worked on schemes in the Gulf region, have skills and experience in high demand, or possess a strong network of professionals here, you&rsquo;ve already increased your chances of landing your first job in Dubai. Combined with a well-crafted CV and Sample Portfolio, this will help you get through the door. But let&rsquo;s get started, as there&rsquo;s a lot to cover.</p>
        <p></p>
        <p>I have also created a mock-up CV just to show you guys which CV layout works well and is easy to read.</p>
        <p></p>
        <h3>How to Format Your Architecture CV for Dubai: Essential Tips</h3>
        <div>File type: PDF</div>
        <div>File size: Max 5MB</div>
        <div>Font type: Easy to read fonts are Arial, Times New Roman or Calibri</div>
        <div>Font size: 10-11</div>
        <div>Pages: Ideally 1 - 2 (if you’re a graduate 1 page is enough)</div>
        <div>Structure: Always start with your most recent work experience</div>
        <div>Naming your file: First and last name - CV. (i.e. Jane Doe - CV)</div>
        <p></p>
        <p></p>
        <h3>Step-by-Step Guide to Structuring an Architecture CV for Dubai Jobs</h3>
        <p>After speaking with several architectural practices and asking what they look for in a CV, it became clear that there isn&rsquo;t a huge difference between a well-written UK CV and one you would send to an employer in Dubai. This is great news for UK professionals. The layout that works well in the UK also functions here, with just a couple of tweaks.</p>
        <p></p>
        <p>A rough outline is as follows:</p>
        <ol>
        <li>Full name, current Job Title, member of an affiliation (ARB, RIBA etc.)</li>
        <li>Photo</li>
        <li>Personal Details (phone with country dial +44, email, location (city and country) and nationality)</li>
        <li>About Me/Profile section (quick snapshot of who you are, your level of experience, current responsibilities, sector experience and highlight that you are moving to Dubai)</li>
        <li>Education (only universities)</li>
        <li>Software Skills - for example, Revit, Rhino, SketchUp, Adobe Creative Suite</li>
        <li>Skills - for example, job running, presentation, leadership, design, and team player (only list the most relevant skills), sector expierences</li>
        <li>Working History - begin with your most recent experience (this is very important)</li>
        <div>8.1. Job Title, Company name, Location, Dates (start - finish) - if it&rsquo;s a contract role - do mention that<br/>8.2. Summarise your responsibilities and transferable skills (if applicable), and mention any work highlights - I worked on a new build mixed-use scheme - supported the team with producing tender packages, attended site meetings and dealt with the planning applications.</div>
        <li>Awards/Certifications (optional)</li>
        <li>Hobbies (optional but could be a nice ice breaker)</li>
        <li>References - Available upon Request</li>
        </ol>
        <p>Below is a CV example I put together to illustrate my point. Personally, I find this CV easy to read, the layout is clear, it has adequate spacing, and it includes all the relevant information I would be looking for. Yes, it&rsquo;s a two-page CV because our Jane Doe has been working for over 12 years, and we don&rsquo;t want to undersell her skills or her experience.</p>
        <p></p>
        <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Jane_Doe_1_Dubai.png" alt="An example CV page 1"></img>
        <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Jane_Doe_2_Dubai.png" alt="An example CV page 2"></img>
        <p/>
        <p>Just to summarise, the biggest differences are: including a photo, stating your nationality and mentioning that you&rsquo;re moving to Dubai. FYI: if you already have a Dubai phone number, include that instead of your UK number. Be super clear about the locations of your work experience and your responsibilities (don&rsquo;t expect someone in Dubai to know the practice or where it&rsquo;s located), and highlight any specific large-scale projects. That&rsquo;s it.</p>
        <p></p>
        <p></p>
        <h3>How to get started&hellip;</h3>
        <p>Most people begin by listing their previous jobs and experiences, however, I recommend starting by searching for suitable job openings before you write anything. This will help you gain a better understanding of the skills employers are looking for, which job titles match your level of experience, and even your potential salary range.</p>
        <p>One thing to keep in mind is that job titles can vary in Dubai, and the title of Architect is not protected. Therefore, take the time to review various job adverts and get a feel for the responsibilities. Your skills and experiences may align better with a Site Architect position or even a Design Manager role.</p>
        <p>Once you find a few interesting job postings, read through them and look for commonly used keywords. Do they mention any specific software (such as Revit, Rhino, Enscape, etc.), leadership skills, design skills, years of experience, level of degree, problem-solving skills, business development, or client-facing abilities? Highlight all relevant keywords, as you will need to incorporate them into your CV.</p>
        <p>The more you tailor your CV to the job brief, the higher the chance someone will contact you.</p>
        <p></p>
        <h3>How do I make my CV stand out?</h3>
        <p>For your CV to stand out, it must be well-written, free of typos, well-structured, and relevant to the position you are applying for - see the section above on How to Format Your Architecture CV for Dubai.</p>
        <p>There are several useful websites where you can find free CV templates. One site I used to recommend to candidates is Canva.com. It&rsquo;s easy to use, and you can choose from a variety of CV templates.</p>
        <p><strong>Tip:</strong> Your sample portfolio goes hand in hand with your CV. Please avoid sending links or QR codes; instead, provide a sample portfolio that serves as a condensed version of your full portfolio, highlighting your best, most recent work and showcasing a good mix of skills and experiences. Keep the file size under 10MB and ideally limit it to 12 pages. Otherwise, recipients may have difficulty opening it, or it may feel too overwhelming.</p>
        <p></p>
        <h3>How Long Should an Architecture CV Be for Dubai? A Recruiter's Advice</h3>
        <p>This is a common question I receive: How long should my CV be? The answer depends on your level of experience. Generally, aim to keep your CV between 1 and 2 pages.</p>
        <p>Rule of thumb:</p>
        <ul>
        <li>Graduates: 1 page</li>
        <li>Professionals: 1-2 pages</li>
        <li>Seniors: 2-3 pages</li>
        </ul>
        <p>To be frank, if I come across a CV longer than 4 pages, I tend to lose interest - I know it sounds harsh but it&rsquo;s the truth. Instead, focus on your last 10 years of experience and keep older or irrelevant roles brief. Don&rsquo;t hesitate to eliminate experiences that don&rsquo;t align with your current career goals.</p>
        <p>For those with more experience, avoid cramming everything onto a single page. As a recruiter, I would find a one-page CV suspicious, which might lead to underselling your skills and achievements. Aim for a sweet spot of 2 pages, concentrating on your most recent 5-10 years, and minimising details from over 20 years ago. Remember, you don&rsquo;t need to list every position you've held.</p>
        <p></p>
        <h3>Understanding ATS: What It Means for Your Application</h3>
        <p>Most recruitment agencies and mid-to large-sized companies use ATS (Applicant Tracking System) software, which allows them to store, filter, organise, and track applications. They can also search their database for suitable candidates by entering relevant keywords (skills, location, job titles, etc.). Usually, there is no point in just emailing your application, as everything needs to be tracked within the ATS, otherwise, it&rsquo;s impossible for the team to manage and organise applications.</p>
        <p>It's important to understand that when submitting your application via an ATS, you're sending it to software and not an actual person. Therefore, your CV needs to be ATS-friendly and optimised to ensure that it passes the initial screening and makes it to a human reviewer.</p>
        <p><strong>Tip:</strong> Here’s a quick way to check if your CV is ATS-friendly. Open your CV, select the entire document (Ctrl + A), and paste it into a blank Word or Google Doc. How does it look? Can you read your CV? How’s the formatting? If you can read it, the ATS will likely be able to as well. If not, the ATS might struggle too.</p>
        <p></p>
        <h3>The Pros and Cons of Visual Elements in Your CV</h3>
        <p></p>
        <p>It&rsquo;s common to see professionals incorporating visually appealing elements like graphs and logos in their CVs. However, keep in mind that if you're submitting your CV through an Applicant Tracking System (ATS), it may not be able to read or interpret these images. If you choose to include logos, make sure to also list the software names next to them, e.g., "Revit, Photoshop, AutoCAD&rdquo;.</p>
        <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Adobe Creative Suite Logos_new.png" alt="Image of the Adobe Creative Suite Logos - Credit to: Godline Art"></img></p>

        <p>When it comes to using visuals like bar charts to represent your skills, they can be misleading. For example, the chart below doesn’t provide context - who are you comparing your skills against? Your proficiency in SketchUp may be stronger than you realise. Instead of relying on a graph, consider sharing concrete information, such as the number of years of experience you have with specific software, software test results or simply listing the software you're comfortable using. Don’t forget, you’ll need to provide evidence of your software abilities in your sample portfolio. If you're telling me you're a strong Revit user, I want to see that.</p>
        <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Software_graph.png" alt="Image of software charts - rating their software abilities"></img></p>
        <p></p>
        <h3>Is a Great CV Enough to Secure a Job in Dubai?</h3>
        <p>A good CV is only part of the puzzle. Remember, many talented people from across the world want to move to Dubai, and not all succeed. You also need a strong portfolio and skills that are in demand. What could really boost your chances are experience working on schemes in the Gulf region, strong Revit and Rhino skills and an established professional network in Dubai.</p>
        <p>Read: <a target="_blank" href="https://archjobsrecruitment.com/articles/crafting-the-perfect-architectural-portfolio-for-dubais-competitive-market">Crafting the Perfect Architectural Portfolio for Dubai’s Competitive Market</a>.</p>
        <p>If you don&rsquo;t have experience working on schemes in KSA or the UAE, it&rsquo;s not a dealbreaker for some companies. However, keep in mind that you&rsquo;ll be competing for the same roles with professionals who may already have experience working on large-scale masterplan schemes or high-rise residential projects. You need to identify your strengths and how you present yourself.</p>
        <p><strong>Highlighting International Experience</strong><br/>When applying for architectural roles in Dubai, it is essential to highlight any international experience you possess. Many firms in Dubai value candidates who bring a global perspective, as the city attracts diverse projects and clientele. In your CV, emphasise any work you've done abroad, showcasing how it has equipped you with unique skills and insights that can benefit your potential employer. Describe specific projects that demonstrate your ability to adapt to different architectural styles, regulations, and cultural contexts. This not only sets you apart from other candidates but also signals to employers that you can thrive in a multicultural environment, which is vital for success in Dubai's dynamic architecture sector.</p>
        <p><strong>Networking Importance</strong><br/>Networking plays a crucial role in securing architectural positions in Dubai, where personal connections can often lead to job opportunities that aren&rsquo;t publicly advertised. Attend industry events, workshops, and seminars to build relationships with professionals in your field. Utilise platforms like LinkedIn to connect with industry leaders and participate in relevant discussions.</p>
        <p>Consider joining professional organisations or online communities focused on architecture in the UAE. By actively engaging with others in the industry, you can gain valuable insights into the local job market, receive recommendations, and learn about job openings before they become widely known. Remember, building a robust professional network can massively enhance your chances of finding the right opportunity.</p>
        <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Networking-Dubai.jpg" alt="RIBA Gulf Chapter Networking Event in Dubai"></img></p>

        <p><strong>Online Presence</strong><br/>Establishing a strong online presence is vital for architectural professionals looking to make their mark in Dubai. Ensure your LinkedIn profile is complete and showcases your skills, experiences, and accomplishments. Engaging with relevant content on social media platforms can further enhance your visibility and credibility within the industry.</p>
        <p></p>
        <h3>Do You Need a Cover Letter for Job Applications in Dubai?</h3>
        <p>It depends on the position and the company. Typically, the job advert will specify whether a cover letter is required. I often receive numerous emails daily from professionals stating they are looking for a job, and a brief summary can be helpful. When I say "short," I mean about 5 to 6 sentences, as I will also review your CV and sample portfolio.</p>
        <p>❌ What won&rsquo;t work is something like, "Hi, I&rsquo;m looking for a job" or "Hi, I have 30 years of experience, can you find me a job?"</p>
        <p>In the first example, it doesn't provide any information about you, nor does it show that you've taken the time to find out my name, which is on every page of my website.</p>
        <p>The second scenario is similar. While it's great that you have 30 years of experience, it doesn't convey much about your specific skills, the projects you&rsquo;ve worked on, or what you are currently looking for.</p>
        <p>&zwj;&zwj;</p>
        <h3>The Importance of Tailoring Your Application Materials</h3>
        <p>The reason why I would always suggest spending that extra time amending your CV/Cover Letter is because recruiters and hiring managers can tell. A generic-sounding application will definitely not help you.</p>
        <p>Don&rsquo;t forget, first impressions count and you only have their attention for a short time. Make every application count - more time spent on tailoring your applications will reduce your job search time massively.</p>
        <p></p>
        <h3>How Recruiters in Dubai Skim CVs: Key Factors They Look For</h3>
        <p>I&rsquo;ve been quite open and honest about this, there is no chance I will read every single word in someone's CV. At first, I skim read your CV and look for certain keywords. I look for job titles, your current location, companies you worked for, length of employment, whether you have experience working on schemes in the Gulf region, and your skill sets.</p>
        <p>That is why a well-structured CV that catches their eye by reflecting keywords from the job description is so important - this is what they are scanning for.</p>
        <p>Once you have their attention, they will look at your CV in more detail.</p>
        <p></p>
        <h3>Choosing Which Experience to Include in Your CV</h3>
        <p>You do not need to list all of your previous jobs on your CV. That usually applies to candidates with more than 15-20 years of experience.</p>
        <p>Also, some might&rsquo;ve worked various contract positions and I have seen CV&rsquo;s which were 6-8 pages long. Please don&rsquo;t do that. The hiring manager is more interested in your recent experience - 5 to 10 years. You can list the previous roles - job title, company, location and dates, but keep it brief (3-4 bullet points are enough).</p>
        <p></p>
        <h3>Should You Include a Photo on Your CV for Dubai Job Applications?</h3>
        <p>When considering whether to include a photo in your CV, it&rsquo;s worth noting that in Dubai, adding a photo is quite common, with about 80% of professionals choosing to do so. Including a professional image can enhance your CV's visual appeal and create a more personal connection with the recruiter.</p>
        <p>However, it's important to ensure that the photo you choose is appropriate. Avoid using selfies or outdated images, as they can give the wrong impression and detract from your professional presentation. Opt for a high-quality, recent photo that reflects your professionalism.</p>
        <p></p>
        <h3>Should I Add Hobbies?</h3>
        <p>Yes, you can add hobbies and I would actually encourage you do to so. But avoid the standard ones like reading, gym, cooking&hellip; be more specific than that. Maybe you and the hiring manager have something in common and it could break the ice and make you more memorable.</p>
        <p></p>
        <h3>Do I need to provide a list of my references on my CV?</h3>
        <p>There is no reason why you should enclose your referee's full contact details i.e. name, phone and email address.</p>
        <p>Just write - &ldquo;Available on request&rdquo; and wait until asked to provide some contact details. That way you stay in full control and you know if your referee will be contacted or not.</p>
        <p></p>
        <h3>Avoid These Common CV Mistakes in the Dubai Architecture Market</h3>
        <p>When writing your CV for the Dubai architecture job market, it's important to avoid common pitfalls that could hinder your chances of landing an interview. One prevalent mistake is using a one-size-fits-all CV, instead, tailor your application to each job by aligning your skills and experiences with the specific requirements outlined in the job description.</p>
        <p>Another mistake is neglecting to proofread your CV, as grammatical errors or typos can give an impression of carelessness.</p>
        <p>Finally, avoid vague language. Instead, use clear and concise descriptions of your accomplishments. I’m not sure why, but I often come across CVs that only list the projects someone worked on. Remember, the reader may have never heard of that particular project in your country, and a project has many stages. Were you involved from start to finish? Did you lead a team? Did you handle all the technical design drawings? Did you liaise with clients and stakeholders? Seriously, I have so many questions. So, please avoid just listing project names - it’s a hugely missed opportunity to actually highlight your skills and responsibilities.</p>
        <p></p>
        <h3>Why Honesty in Your CV Is Important</h3>
        <p>Please, don&rsquo;t lie about your experience on your CV as well as Portfolio. Yes, you might get a call from a recruiter or an invitation to an interview, but they will question you about your skills and experience. You don&rsquo;t want to be called out by hiring managers as they won&rsquo;t give you another chance.</p>
        <p>Be honest and upfront, say that you&rsquo;re looking to gain more experience in X and you&rsquo;re already doing some additional courses. Lying will only waste both your time and the hiring manager's time and most recruiters will not represent you to their clients.</p>
        <p>Let me tell you a short story. I received a really good CV, and their portfolio was stunning. I set up a call with them and asked questions about their day-to-day responsibilities, what they&rsquo;re looking for in their next role, etc. By that point, it became clear they had exaggerated in their CV because their answers were super vague.</p>
        <p>So, I asked them some questions about their portfolio and asked them to walk me through a few projects and renders. Again, incredibly vague answers. Eventually, they admitted: that was done by another team, I was only involved in this particular project at a specific stage, and the render wasn&rsquo;t done by me.</p>
        <p>The moral of the story: don&rsquo;t lie, because we&rsquo;ll find out. There&rsquo;s no chance I would represent you to any of my clients.</p>
        <p></p>
        <h3>Final Thoughts on Writing a strong CV for Dubai</h3>
        <p>Writing a standout architecture CV is important for a successful job search in Dubai but it&rsquo;s only one part of it. You will also need to submit your Sample Portfolio - which is a smaller version of your full portfolio and should be under 12 pages and 10MB - same as in the UK. I would highly suggest to do your research on the different practices here, what sort of skills they are looking for and how you fit in. Dubai&rsquo;s architectural job market is tough and a lot of people have the same dream as you but only a few will make it happen. If you&rsquo;ve just started thinking of relocating to Dubai, I highly recommend you read my article  <a href="https://www.archjobs.co.uk/articles/guide-for-uk-architects-moving-to-dubai" target="_blank" rel="noopener">Essential Guide for UK Architects Moving to Dubai</a>, as it covers everything from moving, costs, how to navigate through the job market and more. If you have any further questions or need guidance, don't hesitate to <a href="https://www.archjobs.co.uk/architectural-career-coaching" target="_blank" rel="noopener">reach out for tailored career coaching</a>.</p>
        <p></p>
        </ArticleFrame>
            }
}