import './App.css';
import { useLocation, Navigate, BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import HomePage from './components/HomePage'
import AdminPage from './components/AdminPage'
import NavBar from './components/NavBar';
import SearchPage from './components/SearchPage'
import PostJob from './components/PostJob'
import Apply from './components/Apply'
import Email from './components/Email'
import GoogleAnalytics from './GoogleAnalytics'
import PrivacyPolicy from './components/PrivacyPolicy'
import Footer from './components/Footer'
import Team from './components/TheTeam'
import WhyWorkWithUs from './components/WhyWorkWithUs'
import FAQPage from './components/FAQ'
import FAQRecruiterPage from './components/FAQRecruiter'
import NewsLetter from './components/NewsLetter'
import SalaryGuide from './components/SalaryGuide';
import RecruiterFind from './components/RecruiterFind';
import RecruiterApply from './components/RecruiterApply';
import WhyWorkWithUsRecruiters from './components/WhyWorkWithUsRecruiters';
import RecruiterPayment from './components/RecruiterPayment';
import WhyWorkWithUsCoaching from './components/WhyWorkWithUsCoaching';
import FAQCoachingPage from './components/FAQCoaching';
import CoachingPayment from './components/CoachingPayment';
// articles
import WriteCV from './articles/WriteCV'
import ArticleList from './articles/ArticleList';
import CoverLetter from './articles/CoverLetter';
import Portfolio from './articles/Portfolio';
import JobInterview from './articles/JobInterview';
import Linkedin from './articles/Linkedin';
import PayIncrease from './articles/PayIncrease';
import CounterOffer from './articles/CounterOffer';
import Resign from './articles/Resign';
import CommonInterviewQuestions from './articles/CommonInterviewQuestions';
import SeniorCV from './articles/SeniorCV';
import JobAd from './articles/JobAd';
import ATS from './articles/ATS';
import ChooseOffers from './articles/ChooseOffers';
import WorkingWithRecruiters from './articles/WorkingWithRecruiters';
import Part1 from './articles/Part1';
import Part2 from './articles/Part2';
import HowToFindARecruiter from './articles/HowToFindARecruiter';
import OverseasArchitect from './articles/OverseasArchitect';
import ATStudy from './articles/ATStudy';
import OvertimeCalculatorPage from './components/OvertimeCalculator';
import Networking from './articles/Networking';
import LivingWages from './articles/LivingWages';
import ArchAdemia from './articles/ArchAdemia';
import DubaiGuide from './articles/DubaiGuide';
import EmploymentContract from './articles/EmploymentContract';
import DubaiCV from './articles/DubaiCV';
import DubaiPortfolio from './articles/DubaiPortfolio';

// hiring guides
import HiringHub from './hiring-guides/HiringHub';
import HiringGuideArchitect from './hiring-guides/HiringGuideArchitect';
import HiringGuideSeniorArchitect from './hiring-guides/HiringGuideSeniorArchitect';
import HiringGuidePart2 from './hiring-guides/HiringGuidePart2';
import HiringGuidePart1 from './hiring-guides/HiringGuidePart1';




function App() {
  return (
    <div className="Page">
      <Router>
        <NavBar/>
        <Routes >
          <Route path="/why-work-with-us" element={<Navigate to="/post-job" />} />
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/search" element={<SearchPage />} />
          <Route path="/search/:searchTerms"  element={<SearchPage />} />
          <Route path="/job/:searchTerms"  element={<SearchPage />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/admin/:searchTerms" element={<AdminPage />} />
          <Route path="/post" element={<PostJob />} />
          <Route path="/apply" element={<Apply/>} />
          <Route path="/test-email" element={<Email/>} />
          <Route path="/privacy" element={<PrivacyPolicy/>} />
          <Route path="/the-team" element={<Team/>} />
          <Route path="/post-job" element={<WhyWorkWithUs/>} />
          <Route path="/faq" element={<FAQPage/>} />
          <Route path="/author/aylin-round" element={<ArticleList author="Aylin Round"/>} />
          <Route path="/articles" element={<ArticleList/>} />
          <Route path="/newsletter" element={<NewsLetter/>} />
          <Route path="/recruiters/join-us" element={<WhyWorkWithUsRecruiters/>} />
          <Route path="/recruiters/check-out" element={<RecruiterPayment/>} />
          <Route path="/recruiters/faq" element={<FAQRecruiterPage/>} />
          <Route path="/recruiters" element={<RecruiterFind/>} />
          <Route path="/recruiters/:recruiter" element={<RecruiterFind/>} />
          <Route path="/recruiter-contact" element={<RecruiterApply/>} />
          <Route path="/unpaid-overtime-calculator" element={<OvertimeCalculatorPage/>} />

          {/* coaching */}
          <Route path="/architectural-career-coaching/faq" element={<FAQCoachingPage/>} />
          <Route path="/architectural-career-coaching/check-out" element={<CoachingPayment/>} />
          <Route path="/architectural-career-coaching" element={<WhyWorkWithUsCoaching/>} />


          <Route path="/salary-guide" element={<SalaryGuide/>} />
          <Route path="/salary-guide/:parameters" element={<SalaryGuide/>} />
          {/* articles */}
          <Route path="/articles/how-to-write-and-structure-your-architectural-cv" element={<WriteCV/>} />
          <Route path="/articles/how-to-structure-an-architecture-cover-letter" element={<CoverLetter/>} />
          <Route path="/articles/how-to-prepare-an-architecture-portfolio" element={<Portfolio/>} />
          <Route path="/articles/how-to-prepare-an-architectural-job-interview" element={<JobInterview/>} />
          <Route path="/articles/how-to-optimise-an-architectural-linkedin-profile" element={<Linkedin/>} />
          <Route path="/articles/architects-guide-how-to-negotiate-a-pay-increase" element={<PayIncrease/>} />
          <Route path="/articles/architects-guide-should-i-accept-a-counter-offer" element={<CounterOffer/>} />
          <Route path="/articles/architects-guide-how-to-hand-in-your-notice" element={<Resign/>} />
          <Route path="/articles/architects-guide-how-to-answer-common-interview-questions" element={<CommonInterviewQuestions/>} />
          <Route path="/articles/how-to-write-a-senior-architectural-cv" element={<SeniorCV/>} />
          <Route path="/articles/how-to-write-an-architectural-job-advert" element={<JobAd/>} />
          <Route path="/articles/how-to-create-an-ats-friendly-architectural-cv" element={<ATS/>} />
          <Route path="/articles/architectural-guide-how-to-choose-between-job-offers" element={<ChooseOffers/>} />
          <Route path="/articles/how-an-architectural-recruiter-can-help-find-your-next-role" element={<WorkingWithRecruiters/>} />
          <Route path="/articles/how-to-find-a-part-1-architectural-assistant-role" element={<Part1/>} />
          <Route path="/articles/how-to-find-your-next-part-2-architectural-assistant-role" element={<Part2/>} />
          <Route path="/articles/how-to-find-an-architectural-recruiter-and-work-with-one" element={<HowToFindARecruiter/>} />
          <Route path="/articles/moving-to-the-uk-as-an-overseas-architect" element={<OverseasArchitect/>} />
          <Route path="/articles/where-to-study-architectural-technology-in-the-uk" element={<ATStudy/>} />
          <Route path="/articles/where-to-find-architectural-networking-events-uk" element={<Networking/>} />
          <Route path="/articles/uk-architectural-salaries-and-living-wages" element={<LivingWages/>} /> 
          <Route path="/articles/guide-for-uk-architects-moving-to-dubai" element={<DubaiGuide/>} /> 
          <Route path="/articles/everything-you-need-to-know-before-signing-an-employment-contract" element={<EmploymentContract/>} /> 
          <Route path="/articles/archademia" element={<ArchAdemia/>} />
          <Route path="/articles/how-to-write-an-architecture-cv-for-the-dubai-market" element={<DubaiCV/>} /> 
          <Route path="/articles/crafting-the-perfect-architectural-portfolio-for-dubais-competitive-market" element={<DubaiPortfolio/>} /> 
          {/* hiring guides */}
          <Route exact path="/hiring" element={<HiringHub/>} />
          <Route path="/hiring/architect-hiring-guide" element={<HiringGuideArchitect/>} />
          <Route path="/hiring/senior-architect-hiring-guide" element={<HiringGuideSeniorArchitect/>} />
          <Route path="/hiring/part2-architectural-assistant-hiring-guide" element={<HiringGuidePart2/>} />
          <Route path="/hiring/part1-architectural-assistant-hiring-guide" element={<HiringGuidePart1/>} />
        </Routes >
        <GoogleAnalytics/>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
